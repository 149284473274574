import type { WithTsToken } from '~/api/types/Authentication'
import _ from 'lodash'

export default async function (data: WithTsToken) {
  if (!data.ts_token || _.isEmpty(data.ts_token)) {
    throw createError({
      statusCode: 422,
      statusMessage: 'Token not provided.',
    })
  }
  return await $fetch(`/api/turnstile`, {
    body: { token: data.ts_token },
    method: 'POST',
  })
}
